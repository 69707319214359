// require jQuery normally
const $ = require('jquery');
require('jquery-ui');


global.$ = global.jQuery = $;

import './packages';
import './main';
import './custom';
