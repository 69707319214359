import noUiSlider from '../packages/nouislider/nouislider.min';
global.noUiSlider = noUiSlider;

import 'jquery';
import 'jquery-ui';
import 'owl.carousel';
import 'magnific-popup';
import '@fortawesome/fontawesome-free';
import 'jquery-lazy/jquery.lazy';
import '../packages/sticky/sticky';
import '../packages/jquery.plugin/jquery.plugin.min';
import '../packages/jquery.count-to/jquery.count-to.min';
import '../packages/jquery.countdown/jquery.countdown.min';
import '../packages/imagesloaded/imagesloaded.pkgd.min';
import '../packages/parallax/parallax.min';
import '../packages/isotope/isotope.pkgd.min';
import '../packages/jquery.gmap/jquery.gmap.min';
