/**
 * Riode Plugin - MiniPopup
 *
 * @instance single
 */

(function ($) {
    'use strict';

  /**
   * @class MiniPopup
   */
  Riode.Minipopup = ( function () {
    var $area,
      offset = 0,
      boxes = [],
      isPaused = false,
      timers = [],
      timerId = false,
      timerInterval = 200,
      timerClock = function () {
        if ( isPaused ) {
          return;
        }
        for ( var i = 0; i < timers.length; ++i ) {
          ( timers[ i ] -= timerInterval ) <= 0 && this.close( i-- );
        }
      }

    return {
      init: function () {
        // init area
        var area = document.createElement( 'div' );
        area.className = "minipopup-area";
        Riode.byClass( 'page-wrapper' )[ 0 ].appendChild( area );

        $area = $( area );
        $area.on( 'click', '.btn-close', function ( e ) {
          self.close( $( this ).closest( '.minipopup-box' ).index() );
        } );

        // bind methods
        this.close = this.close.bind( this );
        timerClock = timerClock.bind( this );
      },

      open: function ( options, callback ) {
        var self = this,
          settings = $.extend( true, {}, Riode.defaults.minipopup, options ),
          $box;
        if ( !settings.isPurchased ) {
          settings.detailTemplate = Riode.parseTemplate(
            ( null != settings.count ? settings.priceQuantityTemplate : settings.priceTemplate ),
            settings
          )
        } else {
          settings.detailTemplate = Riode.parseTemplate(
            settings.purchasedTemplate,
            settings
          )
        }
        if ( null != settings.rating ) {
          settings.detailTemplate += Riode.parseTemplate( settings.ratingTemplate, settings );
        }
        $box = $( Riode.parseTemplate( settings.template, settings ) );

        self.space = settings.space;

        // open
        $box
          .appendTo( $area )
          .css( 'top', - offset )
          .find( "img" )[ 0 ]
          .onload = function () {
          offset += $box[ 0 ].offsetHeight + self.space;

          $box.addClass( 'show' );
          if ( $box.offset().top - window.pageYOffset < 0 ) {
            self.close();
            $box.css( 'top', - offset + $box[ 0 ].offsetHeight + self.space );
          }
          $box.on( 'mouseenter', function () { self.pause() } )
            .on( 'mouseleave', function () { self.resume() } )
            .on( 'touchstart', function ( e ) { self.pause(); e.stopPropagation(); } )
            .on( 'mousedown', function () {
              $( this ).addClass( 'focus' );
            } )
            .on( 'mouseup', function () {
              self.close( $( this ).index() );
            } );
          Riode.$body.on( 'touchstart', function () {
            self.resume();
          } );

          boxes.push( $box );
          timers.push( settings.delay );

          ( timers.length > 1 ) || (
            timerId = setInterval( timerClock, timerInterval )
          )

          callback && callback( $box );
        };
      },

      close: function ( indexToClose ) {
        var self = this,
          index = ( 'undefined' === typeof indexToClose ) ? 0 : indexToClose,
          $box = boxes.splice( index, 1 )[ 0 ];

        // remove timer
        timers.splice( index, 1 )[ 0 ];

        // remove box
        offset -= $box[ 0 ].offsetHeight + self.space;
        $box.removeClass( 'show' );
        setTimeout( function () {
          $box.remove();
        }, 300 );

        // slide down other boxes
        boxes.forEach( function ( $box, i ) {
          if ( i >= index && $box.hasClass( 'show' ) ) {
            $box.stop( true, true ).animate( {
              top: parseInt( $box.css( 'top' ) ) + $box[ 0 ].offsetHeight + 20
            }, 600, 'easeOutQuint' );
          }
        } );

        // clear timer
        boxes.length || clearTimeout( timerId );
      },

      pause: function () {
        isPaused = true;
      },

      resume: function () {
        isPaused = false;
      }
    }
  } )();


})(jQuery);
