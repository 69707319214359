/*
 * This file is part of the Sylius package.
 *
 * (c) Paweł Jędrzejewski
 *
 * For the full copyright and license information, please view the LICENSE
 * file that was distributed with this source code.
 */

import 'semantic-ui-css/components/api';
import $ from 'jquery';

$.fn.extend({
  apiRegistration({
    method,
    dataType = 'json',
    throttle = 0,
    debug = false,
  }) {
    const element = this;
    const formElement = element.find('form');
    const registerButton = element.find('#register-submit');
    const successField = element.find('.success');
    const validationField = element.find('.danger');

    registerButton.api({
      method,
      dataType,
      throttle,
      debug,
      beforeSend(settings) {
        /* eslint-disable-next-line no-param-reassign */
        settings.data = formElement.serialize();

        return settings;
      },

      onSuccess(response) {
        formElement.remove();
        validationField.remove();
        successField.removeClass('d-none');
        successField.addClass('d-block');
        successField.find("div").html(response.message);
      },

      onFailure(response) {
        validationField.removeClass('d-none');
        validationField.addClass('d-block');
        validationField.find("div").html(response.message);
      },
    });
  },
});
