/**
 * Riode Dependent Plugins
 */

(function () {
    var checks = [],
        timerId = false,
        one,
        a, b, o, x, y, ax, ay;

    var checkAll = function () {
        for (var i = checks.length; i--;) {
            one = checks[i];
            a = window.pageXOffset;
            b = window.pageYOffset;
            o = one.el.getBoundingClientRect();
            x = o.left + a;
            y = o.top + b;
            ax = one.options.accX;
            ay = one.options.accY;

            if (y + o.height + ay >= b &&
                y <= b + window.innerHeight + ay &&
                x + o.width + ax >= a &&
                x <= a + window.innerWidth + ax) {

                one.fn.call(one.el, one.data);
                checks.splice(i, 1);
            }
        }
    };

  /**
   * @function appear
   *
   * @param {HTMLElement} el
   * @param {function} fn
   * @param {object} options
   */
  Riode.appear = ( function () {
    var checks = [],
      timerId = false,
      one;

    var checkAll = function () {
      for ( var i = checks.length; i--; ) {
        one = checks[ i ];

        if ( Riode.isOnScreen( one.el, one.options.accX, one.options.accY ) ) {
          typeof $( one.el ).data( 'appear-callback' ) == 'function' && $( one.el ).data( 'appear-callback' ).call( one.el, one.data );
          one.fn && one.fn.call( one.el, one.data );
          checks.splice( i, 1 );
        }
      }
    };

    window.addEventListener( 'scroll', checkAll, { passive: true } );
    window.addEventListener( 'resize', checkAll, { passive: true } );
    $( window ).on( 'appear.check', checkAll );

    return function ( el, fn, options ) {
      var settings = {
        data: undefined,
        accX: 0,
        accY: 0
      };

      if ( options ) {
        options.data && ( settings.data = options.data );
        options.accX && ( settings.accX = options.accX );
        options.accY && ( settings.accY = options.accY );
      }

      checks.push( { el: el, fn: fn, options: settings } );
      if ( !timerId ) {
        timerId = Riode.requestTimeout( checkAll, 100 );
      }
    }
  } )();

    // window.addEventListener('scroll', checkAll, { passive: true });
    // window.addEventListener('resize', checkAll, { passive: true });
    // $(window).on('appear.check', checkAll);
})();

(function ($) {


    Riode.zoomImageObjects = [], // issue
        /**
         * @function zoomImage
         *
         * @requires elevateZoom
         * @param {jQuery} $el
         */

    Riode.zoomImageOnResize = function () {
        Riode.zoomImageObjects.forEach(function ($img) {
            $img.each(function () {
                var elevateZoom = $(this).data('elevateZoom');
                elevateZoom && elevateZoom.refresh();
            })
        });
    }
  /**
   * @function initZoom
   */
  Riode.initZoom = function () {
    window.addEventListener( 'resize', function () {
      Riode.zoomImageObjects.forEach( function ( $img ) {
        $img.each( function () {
          var elevateZoom = $( this ).data( 'elevateZoom' );
          elevateZoom && elevateZoom.refresh();
        } )
      } );
    }, { passive: true } );
  }
  Riode.lazyload = function ( selector, force ) {
    function load () {
      this.setAttribute( 'src', this.getAttribute( 'data-src' ) );
      this.addEventListener( 'load', function () {
        this.style[ 'padding-top' ] = '';
        this.classList.remove( 'lazy-img' );
      } );
    }

    // Lazyload images
    Riode.$( selector ).find( '.lazy-img' ).each( function () {
      if ( 'undefined' != typeof force && force ) {
        load.call( this );
      } else {
        Riode.appear( this, load );
      }
    } )
  }

  /**
   * @function countTo
   *
   * @requires jQuery.countTo
   * @param {string} selector
   */
  Riode.countTo = function ( selector ) {
    if ( $.fn.countTo ) {
      Riode.$( selector ).each( function () {
        Riode.appear( this, function () {
          var $this = $( this );
          setTimeout( function () {
            $this.countTo( {
              onComplete: function () {
                $this.addClass( 'complete' );
              }
            } );
          }, 300 );
        } )
      } );
    }
  }

  /**
   * @function countdown
   *
   * @requires jquery-countdown
   * @param {string} selector
   */
  Riode.countdown = function ( selector ) {
    if ( $.fn.countdown ) {
      Riode.$( selector ).each( function () {
        var $this = $( this ),
          untilDate = $this.data( 'until' ),
          compact = $this.data( 'compact' ),
          dateFormat = ( !$this.data( 'format' ) ) ? 'DHMS' : $this.data( 'format' ),
          newLabels = ( !$this.data( 'labels-short' ) ) ?
            [ 'Years', 'Months', 'Weeks', 'Days', 'Hours', 'Minutes', 'Seconds' ] :
            [ 'Years', 'Months', 'Weeks', 'Days', 'Hours', 'Mins', 'Secs' ],
          newLabels1 = ( !$this.data( 'labels-short' ) ) ?
            [ 'Year', 'Month', 'Week', 'Day', 'Hour', 'Minute', 'Second' ] :
            [ 'Year', 'Month', 'Week', 'Day', 'Hour', 'Min', 'Sec' ];

        var newDate;

        // Split and created again for ie and edge
        if ( !$this.data( 'relative' ) ) {
          var untilDateArr = untilDate.split( ", " ), // data-until 2019, 10, 8 - yy,mm,dd
            newDate = new Date( untilDateArr[ 0 ], untilDateArr[ 1 ] - 1, untilDateArr[ 2 ] );
        } else {
          newDate = untilDate;
        }

        $this.countdown( {
          until: newDate,
          format: dateFormat,
          padZeroes: true,
          compact: compact,
          compactLabels: [ ' y', ' m', ' w', ' days, ' ],
          timeSeparator: ' : ',
          labels: newLabels,
          labels1: newLabels1
        } );
      } );
      // Pause
      // $('.countdown').countdown('pause');
    }

  }


  /**
   * @function priceSlider
   *
   * @requires noUiSlider
   * @param {string} selector
   * @param {object} option
   */
  Riode.priceSlider = function ( selector, option ) {
    if ( typeof noUiSlider === 'object' ) {
      Riode.$( selector ).each( function () {
        var self = this;

        noUiSlider.create( self, $.extend( true, {
          start: [ 18, 35 ],
          connect: true,
          step: 1,
          range: {
            min: 18,
            max: 35
          }
        }, option ) );

        // Update Price Range
        self.noUiSlider.on( 'update', function ( values, handle ) {
          var values = values.map( function ( value ) {
            return '$' + parseInt( value );
          } )
          $( self ).parent().find( '.filter-price-range' ).text( values.join( ' - ' ) );
        } );
      } );
    }
  }
  /**
   * @function isotopes
   *
   * @requires isotope,imagesLoaded
   * @param {string} selector
   * @param {object} options
   */
  Riode.isotopes = function ( selector, options ) {
    if ( typeof imagesLoaded === 'function' && $.fn.isotope ) {
      var self = this;

      Riode.$( selector ).each( function () {
        var $this = $( this ),
          settings = $.extend( true, {},
            Riode.defaults.isotope,
            Riode.parseOptions( $this.attr( 'data-grid-options' ) ),
            options ? options : {}
          );

        Riode.lazyload( $this );
        $this.imagesLoaded( function () {
          settings.customInitHeight && $this.height( $this.height() );
          settings.customDelay && Riode.call( function () {
            $this.isotope( settings );
          }, parseInt( settings.customDelay ) );

          $this.isotope( settings );
        } )
      } );
    }
  }

  /**
   * @function initNavFilter
   *
   * @requires isotope
   * @param {string} selector
   */
  Riode.initNavFilter = function ( selector ) {
    if ( $.fn.isotope ) {
      Riode.$( selector ).on( 'click', function ( e ) {
        var $this = $( this ),
          filterValue = $this.attr( 'data-filter' ),
          filterTarget = $this.parent().parent().attr( 'data-target' );
        ( filterTarget ? $( filterTarget ) : $( '.grid' ) )
          .isotope( { filter: filterValue } )
          .isotope( 'on', 'arrangeComplete', function () {
            Riode.$window.trigger( 'appear.check' );
          } );
        $this.parent().siblings().children().removeClass( 'active' );
        $this.addClass( 'active' );
        e.preventDefault();
      } );
    }
  }

  /**
   * @function initShowVendorSearch
   *
   * @param {string} selector
   */
  Riode.initShowVendorSearch = function ( selector ) {
    Riode.$body.on( 'click', selector, function ( e ) {

      var $this = $( this ),
        $formWrapper = $this.closest( '.toolbox' ).next( '.form-wrapper' );

      if ( !$formWrapper.hasClass( 'open' ) ) {
        $formWrapper.slideDown().addClass( 'open' );
      } else {
        $formWrapper.slideUp().removeClass( 'open' );
      }
      e.preventDefault();
    } );
  }

  /**
   * @function parallax
   * Initialize Parallax Background
   * @requires themePluginParallax
   * @param {string} selector
   */
  Riode.parallax = function ( selector, options ) {
    if ( $.fn.themePluginParallax ) {
      Riode.$( selector ).each( function () {
        var $this = $( this );
        $this.themePluginParallax(
          $.extend( true, Riode.parseOptions( $this.attr( 'data-parallax-options' ) ), options )
        );
      } );
    }
  }

  /**
   * @function headerToggleSearch
   * Init header toggle search.
   * @param {string} selector
   */

  Riode.headerToggleSearch = function ( selector ) {
    var $search = Riode.$( selector );
    $search.find( '.form-control' )
      .on( 'focusin', function ( e ) {
        $search.addClass( 'show' );
      } )
      .on( 'focusout', function ( e ) {
        $search.removeClass( 'show' );
      } );

    // Initialize sticky footer's search toggle.
    Riode.$body.on( 'click', '.sticky-footer .search-toggle', function ( e ) {
      $( this ).parent().toggleClass( 'show' );
      e.preventDefault();
    } );
  }

  /**
   * @function closeTopNotice
   * Init header toggle search.
   * @param {string} selector
   */

  Riode.closeTopNotice = function ( selector ) {
    var $closeBtn = Riode.$( selector );
    $closeBtn.on( 'click', function ( e ) {
      e.preventDefault();
      $closeBtn.closest( '.top-notice' ).css( 'display', 'none' );
    } );
  }

  /**
   * @function stickyHeader
   * Init sticky header
   * @param {string} selector
   */
  Riode.stickyHeader = function ( selector ) {
    var $stickyHeader = Riode.$( selector );
    if ( $stickyHeader.length == 0 ) return;

    var height, top, isWrapped = false;

    // define wrap function
    var stickyHeaderWrap = function () {
      height = $stickyHeader[ 0 ].offsetHeight;
      top = $stickyHeader.offset().top + height;

      // if sticky header has category dropdown, increase top
      if ( $stickyHeader.hasClass( 'has-dropdown' ) ) {
        var $box = $stickyHeader.find( '.category-dropdown .dropdown-box' );

        if ( $box.length ) {
          top += $stickyHeader.find( '.category-dropdown .dropdown-box' )[ 0 ].offsetHeight;
        }
      }

      // wrap sticky header
      if ( !isWrapped && window.innerWidth >= Riode.defaults.stickyHeader.activeScreenWidth ) {
        isWrapped = true;
        $stickyHeader.wrap( '<div class="sticky-wrapper" style="height:' + height + 'px"></div>' );
      }

      Riode.$window.off( 'resize', stickyHeaderWrap );
    };

    // define refresh function
    var stickyHeaderRefresh = function () {
      var isFixed = window.innerWidth >= Riode.defaults.stickyHeader.activeScreenWidth && window.pageYOffset >= top;

      // fix or unfix
      if ( isFixed ) {
        $stickyHeader[ 0 ].classList.add( 'fixed' );
        document.body.classList.add( 'sticky-header-active' );
      } else {
        $stickyHeader[ 0 ].classList.remove( 'fixed' );
        document.body.classList.remove( 'sticky-header-active' );
      }
    };

    // register events
    window.addEventListener( 'scroll', stickyHeaderRefresh, { passive: true } );
    Riode.$window.on( 'resize', stickyHeaderWrap );
    Riode.$window.on( 'resize', stickyHeaderRefresh );

    // init
    Riode.call( stickyHeaderWrap, 500 );
    Riode.call( stickyHeaderRefresh, 500 );
  }

  /**
   * @function stickyContent
   * Init Sticky Content
   * @param {string, Object} selector
   * @param {Object} settings
   */
  Riode.stickyContent = function ( selector, settings ) {
    var $stickyContents = Riode.$( selector ),
      options = $.extend( {}, Riode.defaults.stickyContent, settings ),
      scrollPos = window.pageYOffset;

    if ( 0 == $stickyContents.length ) return;

    var setTopOffset = function ( $item ) {
      var offset = 0,
        index = 0;
      $( '.sticky-content.fixed.fix-top' ).each( function () {
        offset += $( this )[ 0 ].offsetHeight;
        index++;
      } );
      $item.data( 'offset-top', offset );
      $item.data( 'z-index', options.max_index - index );
    }

    var setBottomOffset = function ( $item ) {
      var offset = 0,
        index = 0;
      $( '.sticky-content.fixed.fix-bottom' ).each( function () {
        offset += $( this )[ 0 ].offsetHeight;
        index++;
      } );
      $item.data( 'offset-bottom', offset );
      $item.data( 'z-index', options.max_index - index );
    }

    var wrapStickyContent = function ( $item, height ) {
      if ( window.innerWidth >= options.minWidth && window.innerWidth <= options.maxWidth ) {
        $item.wrap( '<div class="sticky-content-wrapper"></div>' );
        $item.parent().css( 'height', height + 'px' );
        $item.data( 'is-wrap', true );
      }
    }

    var initStickyContent = function () {
      $stickyContents.each( function ( index ) {
        var $item = $( this );
        if ( !$item.data( 'is-wrap' ) ) {
          var height = $item.removeClass( 'fixed' ).outerHeight( true ), top;
          top = $item.offset().top + height;

          // if sticky header has category dropdown, increase top
          if ( $item.hasClass( 'has-dropdown' ) ) {
            var $box = $item.find( '.category-dropdown .dropdown-box' );

            if ( $box.length ) {
              top += $box[ 0 ].offsetHeight;
            }
          }

          $item.data( 'top', top );
          wrapStickyContent( $item, height );
        } else {
          if ( window.innerWidth < options.minWidth || window.innerWidth >= options.maxWidth ) {
            $item.unwrap( '.sticky-content-wrapper' );
            $item.data( 'is-wrap', false );
          }
        }
      } );
    }

    var refreshStickyContent = function ( e ) {
      if ( e && !e.isTrusted ) return;
      $stickyContents.each( function ( index ) {
        var $item = $( this ),
          showContent = true;
        if ( options.scrollMode ) {
          showContent = scrollPos > window.pageYOffset;
          scrollPos = window.pageYOffset;
        }
        if ( window.pageYOffset > ( false == options.top ? $item.data( 'top' ) : options.top ) && window.innerWidth >= options.minWidth && window.innerWidth <= options.maxWidth ) {
          if ( $item.hasClass( 'fix-top' ) ) {
            if ( undefined === $item.data( 'offset-top' ) ) {
              setTopOffset( $item );
            }
            $item.css( 'margin-top', $item.data( 'offset-top' ) + 'px' );
          } else if ( $item.hasClass( 'fix-bottom' ) ) {
            if ( undefined === $item.data( 'offset-bottom' ) ) {
              setBottomOffset( $item );
            }
            $item.css( 'margin-bottom', $item.data( 'offset-bottom' ) + 'px' );
          }
          $item.css( 'z-index', $item.data( 'z-index' ) );
          if ( options.scrollMode ) {
            if ( ( showContent && $item.hasClass( 'fix-top' ) ) || ( !showContent && $item.hasClass( 'fix-bottom' ) ) ) {
              $item.addClass( 'fixed' );
            } else {
              $item.removeClass( 'fixed' );
              $item.css( 'margin', '' );
            }
          } else {
            $item.addClass( 'fixed' );
          }
          options.hide && $item.parent( '.sticky-content-wrapper' ).css( 'display', '' );
        } else {
          $item.removeClass( 'fixed' );
          $item.css( 'margin-top', '' );
          $item.css( 'margin-bottom', '' );
          options.hide && $item.parent( '.sticky-content-wrapper' ).css( 'display', 'none' );
        }
      } );
    }

    var resizeStickyContent = function ( e ) {
      $stickyContents.removeData( 'offset-top' )
        .removeData( 'offset-bottom' )
        .removeClass( 'fixed' )
        .css( 'margin', '' )
        .css( 'z-index', '' );

      Riode.call( function () {
        initStickyContent();
        refreshStickyContent();
      } );
    }

    setTimeout( initStickyContent, 550 );
    setTimeout( refreshStickyContent, 600 );

    Riode.call( function () {
      window.addEventListener( 'scroll', refreshStickyContent, { passive: true } );
      Riode.$window.on( 'resize', resizeStickyContent );
    }, 700 );
  }

  /**
   * @function alert
   * Register events for alert
   * @param {string} selector
   */
  Riode.initAlert = function ( selector ) {
    Riode.$body.on( 'click', selector + ' .btn-close', function ( e ) {
      $( this ).closest( selector ).fadeOut( function () {
        $( this ).remove();
      } );
    } );
  }


  /**
   * @function accordion
   * Register events for accordion
   * @param {string} selector
   */
  Riode.initAccordion = function ( selector ) {
    Riode.$body.on( 'click', selector, function ( e ) {
      var $this = $( this ),
        $header = $this,
        $body = $this.closest( '.card' ).find( $this.attr( 'href' ) ),
        $parent = $this.closest( '.accordion' );

      e.preventDefault();

      if ( 0 === $parent.find( ".collapsing" ).length &&
        0 === $parent.find( ".expanding" ).length ) {

        if ( $body.hasClass( 'expanded' ) ) {
          if ( !$parent.hasClass( 'radio-type' ) )
            slideToggle( $body );

        } else if ( $body.hasClass( 'collapsed' ) ) {

          if ( $parent.find( '.expanded' ).length > 0 ) {
            if ( Riode.isIE ) {
              slideToggle( $parent.find( '.expanded' ), function () {
                slideToggle( $body );
              } );

            } else {
              slideToggle( $parent.find( '.expanded' ) );
              slideToggle( $body );
            }
          } else {
            slideToggle( $body );
          }
        }
      }
    } );

    // define slideToggle method
    var slideToggle = function ( $wrap, callback ) {
      var $header = $wrap.closest( '.card' ).find( selector );

      if ( $wrap.hasClass( "expanded" ) ) {
        $header
          .removeClass( "collapse" )
          .addClass( "expand" );
        $wrap
          .addClass( "collapsing" )
          .slideUp( 300, function () {
            $wrap.removeClass( "expanded collapsing" ).addClass( "collapsed" );
            callback && callback();
          } )

      } else if ( $wrap.hasClass( "collapsed" ) ) {
        $header
          .removeClass( "expand" )
          .addClass( "collapse" );
        $wrap
          .addClass( "expanding" )
          .slideDown( 300, function () {
            $wrap.removeClass( "collapsed expanding" ).addClass( "expanded" );
            callback && callback();
          } )
      }
    };
  }


  /**
   * @function tab
   * Register events for tab
   * @param {string} selector
   */
  Riode.initTab = function ( selector ) {

    Riode.$body
      // tab nav link
      .on( 'click', '.tab .nav-link', function ( e ) {
        var $this = $( this );
        e.preventDefault();

        if ( !$this.hasClass( "active" ) ) {
          var $panel = $( $this.attr( 'href' ) );
          $panel.siblings().removeClass( 'in active' );
          $panel.addClass( 'active in' );

          // owl-carousel init
          Riode.slider( $panel.find( '.owl-carousel' ) );


          $this.parent().parent().find( '.active' ).removeClass( 'active' );
          $this.addClass( 'active' );
        }
      } )

      // link to tab
      .on( 'click', '.link-to-tab', function ( e ) {
        var selector = $( e.currentTarget ).attr( 'href' ),
          $tab = $( selector ),
          $nav = $tab.parent().siblings( '.nav' );
        e.preventDefault();

        $tab.siblings().removeClass( 'active in' );
        $tab.addClass( 'active in' );
        $nav.find( '.nav-link' ).removeClass( 'active' );
        $nav.find( '[href="' + selector + '"]' ).addClass( 'active' );

        $( 'html' ).animate( {
          scrollTop: $tab.offset().top - 150
        } );
      } );
  }

  /**
   * @function playableVideo
   *
   * @param {string} selector
   */
  Riode.playableVideo = function ( selector ) {
    $( selector + ' .video-play' ).on( 'click', function ( e ) {
      var $video = $( this ).closest( selector );
      if ( $video.hasClass( 'playing' ) ) {
        $video.removeClass( 'playing' )
          .addClass( 'paused' )
          .find( 'video' )[ 0 ].pause();
      } else {
        $video.removeClass( 'paused' )
          .addClass( 'playing' )
          .find( 'video' )[ 0 ].play();
      }
      e.preventDefault();
    } );
    $( selector + ' video' ).on( 'ended', function () {
      $( this ).closest( selector ).removeClass( 'playing' );
    } );
  }

  /**
   * @function appearAnimate
   *
   * @param {string} selector
   */
  Riode.appearAnimate = function ( selector ) {
    Riode.$( selector ).each( function () {
      var el = this;
      Riode.appear( el, function () {
        if ( el.classList.contains( 'appear-animate' ) ) {
          var settings = $.extend( {}, Riode.defaults.animation, Riode.parseOptions( el.getAttribute( 'data-animation-options' ) ) );

          Riode.call( function () {
            setTimeout(
              function () {
                el.style[ 'animation-duration' ] = settings.duration;
                el.classList.add( settings.name );
                el.classList.add( 'appear-animation-visible' );
              },
              settings.delay ? Number( settings.delay.slice( 0, -1 ) ) * 1000 : 0
            );
          } );
        }
      } );
    } );
  }

  /**
   * @function stickySidebar
   *
   * @requires themeSticky
   * @param {string} selector
   */
  Riode.stickySidebar = function ( selector ) {
    if ( $.fn.themeSticky ) {
      Riode.$( selector ).each( function () {
        var $this = $( this );
        $this.themeSticky( $.extend( Riode.defaults.stickySidebar, Riode.parseOptions( $this.attr( 'data-sticky-options' ) ) ) );
        $this.trigger( 'recalc.pin' );
      } );
    }
  }
  /**
   * @function refreshSidebar
   *
   * @param {string} selector
   */
  Riode.refreshSidebar = function ( selector ) {
    if ( $.fn.themeSticky ) {
      Riode.$( selector ).each( function () {
        $( this ).trigger( 'recalc.pin' );
      } );
    }
  }

  /**
   * @function ratingTooltip
   * Find all .ratings-full from root, and initialize tooltip.
   *
   * @param {HTMLElement} root
   */
  Riode.ratingTooltip = function ( root ) {
    var els = Riode.byClass( 'ratings-full', root ? root : document.body ),
      len = els.length;

    var ratingHandler = function () {
      var res = parseInt( this.firstElementChild.style.width.slice( 0, -1 ) ) / 20;
      this.lastElementChild.innerText = res ? res.toFixed( 2 ) : res;
    }
    for ( var i = 0; i < len; ++i ) {
      els[ i ].addEventListener( 'mouseover', ratingHandler );
      els[ i ].addEventListener( 'touchstart', ratingHandler );
    }
  }



  /**
   * @function popup
   * @requires magnificPopup
   * @params {object} options
   * @params {string|undefined} preset
   */
  Riode.popup = function ( options, preset ) {
    var mpInstance = $.magnificPopup.instance,
      opt = $.extend( true, {},
        Riode.defaults.popup,
        ( 'undefined' != typeof preset ) ? Riode.defaults.popupPresets[ preset ] : {},
        options
      );

    // if something is already opened ( except login popup )
    if ( mpInstance.isOpen && mpInstance.content ) {
      mpInstance.close(); // close current
      setTimeout( function () { // and open new after a moment
        $.magnificPopup.open( opt );
      }, 500 );
    } else {
      $.magnificPopup.open( opt ); // if nothing is opened, open new
    }
  }

  /**
   * @function initPopups
   */
  Riode.initPopups = function () {

    Riode.$body
      // Register Login Popup
      .on( 'click', '.fast-contact-link', function ( e ) {
        e.preventDefault();
        Riode.popup( {
          items: {
            src: $( e.currentTarget ).attr( 'href' )
          }
        }, 'fast-contact' );

      } )

      // Register Login Popup
      .on( 'click', 'a.login, .login-link', function ( e ) {
        e.preventDefault();
        Riode.popup( {
          items: {
            src: $( e.currentTarget ).attr( 'href' )
          }
        }, 'login' );

      } )

      // Register "Register" Popup
      .on( 'click', '.register-link', function ( e ) {
        e.preventDefault();
        Riode.popup( {
          items: {
            src: $( e.currentTarget ).attr( 'href' )
          },
          callbacks: {
            ajaxContentAdded: function () {
              this.wrap.find( '[href="#register"]' ).click();
            }
          }
        }, 'login' );
      } )

      // Register "Play Video" Popup
      .on( 'click', '.btn-iframe', function ( e ) {
        e.preventDefault();
        Riode.popup( {
          items: {
            src: '<video src="' + $( e.currentTarget ).attr( 'href' ) + '" autoplay loop controls>',
            type: "inline"
          },
          mainClass: "mfp-video-popup"
        }, "video" )
      } );

    // Open newsletter Popup after 7.5s in home pages
    if ( $('#newsletter-popup').length > 0 && Riode.getCookie( 'hideNewsletterPopup' ) !== 'true' ) {
      setTimeout( function () {
        Riode.popup( {
          items: {
            src: '#newsletter-popup'
          },
          type: 'inline',
          tLoading: '',
          mainClass: 'mfp-newsletter mfp-flip-popup',
          callbacks: {
            beforeClose: function () {
              // if "do not show" is checked
              $( '#hide-newsletter-popup' )[ 0 ].checked && Riode.setCookie( 'hideNewsletterPopup', true, 7 );
            }
          },
        } );
      }, 7500 );
    }
  }

  // /**
  //  * @function initPurchasedMinipopup
  //  */
  // Riode.initPurchasedMinipopup = function () {
  //   setInterval( function () {
  //     Riode.Minipopup.open( {
  //       message: 'Someone Purchased',
  //       productClass: 'product-cart',
  //       name: 'Daisy Shoes Sonia by Sonia-Blue',
  //       nameLink: 'product.html',
  //       imageSrc: 'images/cart/product-1.jpg',
  //       isPurchased: true
  //     }, function ( $box ) {
  //       Riode.ratingTooltip( $box[ 0 ] );
  //     } );
  //   }, 60000 );
  // }

  /**
   * @function initScrollTopButton
   */
  Riode.initScrollTopButton = function () {
    // register scroll top button

    var domScrollTop = Riode.byId( 'scroll-top' );

    if ( domScrollTop ) {
      domScrollTop.addEventListener( 'click', function ( e ) {
        $( 'html, body' ).animate( { scrollTop: 0 }, 600 );
        e.preventDefault();
      } );

      var refreshScrollTop = function () {
        if ( window.pageYOffset > 400 ) {
          domScrollTop.classList.add( 'show' );
        } else {
          domScrollTop.classList.remove( 'show' );
        }
      }

      Riode.call( refreshScrollTop, 500 );
      window.addEventListener( 'scroll', refreshScrollTop, { passive: true } );
    }
  }

  /**
   * @function requestTimeout
   * @param {function} fn
   * @param {number} delay
   */
  Riode.requestTimeout = function ( fn, delay ) {
    var handler = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame;
    if ( !handler ) {
      return setTimeout( fn, delay );
    }
    var start, rt = new Object();

    function loop ( timestamp ) {
      if ( !start ) {
        start = timestamp;
      }
      var progress = timestamp - start;
      progress >= delay ? fn() : rt.val = handler( loop );
    };

    rt.val = handler( loop );
    return rt;
  }

  /**
   * @function requestInterval
   * @param {function} fn
   * @param {number} step
   * @param {number} timeOut
   */
  Riode.requestInterval = function ( fn, step, timeOut ) {
    var handler = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame;
    if ( !handler ) {
      if ( !timeOut )
        return setTimeout( fn, timeOut );
      else
        return setInterval( fn, step );
    }
    var start, last, rt = new Object();
    function loop ( timestamp ) {
      if ( !start ) {
        start = last = timestamp;
      }
      var progress = timestamp - start;
      var delta = timestamp - last;
      if ( !timeOut || progress < timeOut ) {
        if ( delta > step ) {
          rt.val = handler( loop );
          fn();
          last = timestamp;
        } else {
          rt.val = handler( loop );
        }
      } else {
        fn();
      }
    };
    rt.val = handler( loop );
    return rt;
  }

  /**
   * @function deleteTimeout
   * @param {number} timerID
   */
  Riode.deleteTimeout = function ( timerID ) {
    if ( !timerID ) {
      return;
    }
    var handler = window.cancelAnimationFrame || window.webkitCancelAnimationFrame || window.mozCancelAnimationFrame;
    if ( !handler ) {
      return clearTimeout( timerID );
    }
    if ( timerID.val ) {
      return handler( timerID.val );
    }
  }
})(jQuery);


(function ($) {

  /**
   * @class Menu
   */
  Riode.Menu = {
    menuTimeout: null,
    init: function () {
      this.initMenu();
      this.initMobileMenu();
      this.initFilterMenu();
      this.initCategoryMenu();
      this.initCollapsibleWidget();
    },
    initMenu: function () {
      // setup menu
      $( '.menu li' ).each( function () {
        if ( this.lastElementChild && (
          this.lastElementChild.tagName === 'UL' ||
          this.lastElementChild.classList.contains( 'megamenu' ) )
        ) {
          this.classList.add( 'submenu' );
        }
      } );

      $( '.main-nav .submenu' ).each( function () {
        var $this = $(this);
        $this.on("mouseover", function (e) {

          $(this).find('.img-menu-lazy').lazy({
            bind: "event",
            delay: 0
          });
          console.log('ted');
        });


      });



      $( '.main-nav .megamenu, .main-nav .submenu > ul' ).each( function () {
        var $this = $(this),
          left = $this.offset().left,
          outerWidth = $this.outerWidth(),
          offset = (left + outerWidth) - (window.innerWidth);

        $this.data('menu-offset',offset);
      });

      this.recalcMegamenu();

      Riode.$window.on('resize', function () {
        clearTimeout(Riode.Menu.menuTimeout);
        Riode.Menu.menuTimeout = setTimeout(Riode.Menu.recalcMegamenu, 100);
        ;
      })


    },
    recalcMegamenu: function () {
      $( '.main-nav .megamenu, .main-nav .submenu > ul' ).each( function () {
        var $this = $( this ),
          left = $this.offset().left,
          outerWidth = $this.outerWidth(),
          offset = ( left + outerWidth ) - ( window.innerWidth  );


        $this.data('menu-offset',offset);
        $this.css( 'margin-left', '-=' + offset );

      } );
    },
    initMobileMenu: function () {
      function showMobileMenu () {
        Riode.$body.addClass( 'mmenu-active' );
      };
      function hideMobileMenu () {
        Riode.$body.removeClass( 'mmenu-active' );
      };

      $( '.mobile-menu li, .toggle-menu li' ).each( function () {
        if ( this.lastElementChild && (
          this.lastElementChild.tagName === 'UL' ||
          this.lastElementChild.classList.contains( 'megamenu' ) )
        ) {
          var span = document.createElement( 'span' );
          span.className = "toggle-btn";
          this.firstElementChild.appendChild( span );
        }
      } );
      $( '.mobile-menu-toggle' ).on( 'click', showMobileMenu );
      $( '.mobile-menu-overlay' ).on( 'click', hideMobileMenu );
      $( '.mobile-menu-close' ).on( 'click', hideMobileMenu );
      Riode.$window.on( 'resize', hideMobileMenu );
    },
    initFilterMenu: function () {
      $( '.search-ul li' ).each( function () {
        if ( this.lastElementChild && this.lastElementChild.tagName === 'UL' ) {
          var i = document.createElement( 'i' );
          i.className = "fas fa-chevron-down";
          this.classList.add( 'with-ul' );
          this.firstElementChild.appendChild( i );
        }
      } );
      $( '.with-ul > a i, .toggle-btn' ).on( 'click', function ( e ) {
        $( this ).parent().next().slideToggle( 300 ).parent().toggleClass( "show" );
        setTimeout( function () {
          $( '.sticky-sidebar' ).trigger( 'recalc.pin' );
        }, 320 );
        e.preventDefault();
      } );
    },
    initCategoryMenu: function () {
      // cat dropdown
      var $menu = $( '.category-dropdown' );
      if ( $menu.length ) {
        var $box = $menu.find( '.dropdown-box' );
        if ( $box.length ) {
          var top = $( '.main' ).offset().top + $box[ 0 ].offsetHeight;
          if ( window.pageYOffset > top || window.innerWidth < Riode.minDesktopWidth ) {
            $menu.removeClass( 'show' );
          }
          window.addEventListener( 'scroll', function () {
            if ( window.pageYOffset <= top && window.innerWidth >= Riode.minDesktopWidth ) {
              $menu.removeClass( 'show' );
            }
          }, { passive: true } );
          $( '.category-toggle' ).on( "click", function ( e ) {
            e.preventDefault();
          } )
          $menu.on( "mouseover", function ( e ) {
            if ( window.pageYOffset > top && window.innerWidth >= Riode.minDesktopWidth ) {
              $menu.addClass( 'show' );
            }
          } )
          $menu.on( "mouseleave", function ( e ) {
            if ( window.pageYOffset > top && window.innerWidth >= Riode.minDesktopWidth ) {
              $menu.removeClass( 'show' );
            }
          } )
        }
        if ( $menu.hasClass( 'with-sidebar' ) ) {
          var sidebar = Riode.byClass( 'sidebar' );
          if ( sidebar.length ) {
            $menu.find( '.dropdown-box' ).css( 'width', sidebar[ 0 ].offsetWidth - 20 );

            // set category menu's width same as sidebar.
            Riode.$window.on( 'resize', function () {
              $menu.find( '.dropdown-box' ).css( 'width', ( sidebar[ 0 ].offsetWidth - 20 ) );
            } );
          }
        }
      }
    },
    initCollapsibleWidget: function () {
      // generate toggle icon
      $( '.widget-collapsible .widget-title' ).each( function () {
        var span = document.createElement( 'span' );
        span.className = 'toggle-btn';
        this.appendChild( span );
      } );
      // slideToggle
      $( '.widget-collapsible .widget-title' ).on( 'click', function ( e ) {
        var $this = $( this );
        if ( !$this.hasClass( 'sliding' ) ) {
          var $body = $this.siblings( '.widget-body' );

          $this.hasClass( "collapsed" ) || $body.css( 'display', 'block' );

          $this.addClass( "sliding" );
          $body.slideToggle( 300, function () {
            $this.removeClass( "sliding" );
          } );

          $this.toggleClass( "collapsed" );
          setTimeout( function () {
            $( '.sticky-sidebar' ).trigger( 'recalc.pin' );
          }, 320 );
        }
      } );
    }
  };



})(jQuery);
