/**
 * Riode Theme
 */
(function ($) {

  /**
   * Riode Initializer
   */
  // Initialize Method while document is being loaded.
  Riode.prepare = function () {
    if ( Riode.$body.hasClass( 'with-flex-container' ) && window.innerWidth >= 1200 ) {
      Riode.$body.addClass( 'sidebar-active' );
    }
  };

  // Initialize Method while document is interactive
  Riode.initLayout = function () {
    Riode.isotopes( '.grid:not(.grid-float)' );
    Riode.stickySidebar( '.sticky-sidebar' );
  }

  // Initialize Method after document has been loaded
  Riode.init = function () {
    Riode.appearAnimate( '.appear-animate' );                               // Runs appear animations
    Riode.Minipopup.init();                                                 // Initialize minipopup
    Riode.Shop.init();                                                      // Initialize shop
    Riode.initProductSinglePage();                                          // Initialize single product page
    Riode.slider( '.owl-carousel' );                                        // Initialize slider
    Riode.headerToggleSearch( '.hs-toggle' );                               // Initialize header toggle search
    Riode.closeTopNotice( '.btn-notice-close' );                            // Initialize header toggle search
    Riode.stickyContent( '.product-sticky-content, .sticky-header', { top: false } );       // Initialize sticky content
    Riode.stickyContent( '.sticky-footer', Riode.defaults.stickyFooter );   // Initialize sticky footer
    Riode.stickyContent( '.sticky-toolbox', Riode.defaults.stickyToolbox ); // Initialize sticky toolbox
    Riode.sidebar( 'sidebar' );                                             // Initialize left sidebar
    Riode.sidebar( 'right-sidebar' );                                       // Initialize right sidebar
    Riode.sidebar( 'top-sidebar' );                    	                    // Initialize right sidebar
    Riode.quantityInput( '.quantity' );                                     // Initialize quantity input
    Riode.playableVideo( '.post-video' );                                   // Initialize playable video
    Riode.initAccordion( '.card-header > a' );                              // Initialize accordion
    Riode.initTab( '.nav-tabs' );                                           // Initialize tab
    Riode.initAlert( '.alert' );                                            // Initialize alert
    Riode.parallax( '.parallax' );                                          // Initialize parallax
    Riode.countTo( '.count-to' );                                           // Initialize countTo
    Riode.countdown( '.product-countdown, .countdown' );                    // Initialize countdown
    Riode.Menu.init();                                                      // Initialize menus
    Riode.initZoom();                                                       // Initialize zoom
    Riode.initNavFilter( '.nav-filters .nav-filter' );                      // Initialize navigation filters for blog, products
    Riode.initPopups();                                                     // Initialize popups: login, register, play video, newsletter popup
    // Riode.initPurchasedMinipopup();                                         // Initialize minipopup for purchased event
    Riode.initScrollTopButton();                                            // Initialize scroll top button.
    Riode.floatSVG( '.float-svg' );						                    // Floating SVG
    Riode.initShowVendorSearch( '.toolbox .form-toggle-btn' );              // Initialize show vendor search form

    Riode.status = 'complete';
  }
})(jQuery);

/**
 * Riode Theme Initializer
 */
(function ($) {
    'use strict';

  /**
   * Setup Riode
   */

  // Prepare Riode Theme
  Riode.prepare();

  // Initialize Riode Theme
  window.onload = function () {
    Riode.status = 'loaded';
    Riode.$body.addClass( 'loaded' );
    Riode.$window.trigger( 'riode_load' );

    Riode.call( Riode.initLayout );
    Riode.call( Riode.init );
    Riode.$window.trigger( 'riode_complete' );
    Riode.refreshSidebar();
  }
})(jQuery);

