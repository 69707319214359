import './main/base';
import './main/easing';
import './main/slider';
import './main/floatSvg';
import './main/minipopup';
import './main/plugins';
import './main/popup';

import './main/product-single';
import './main/product-single-page';
import './main/quantity-input';
import './main/register';
import './main/shop';
import './main/sidebar';

import './main/theme';
