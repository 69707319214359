/**
 * Riode Main Javascript File
 */
"use strict";

var $ = jQuery.noConflict();


/**
 * Riode Object
 */
window.Riode = {};
/**
 * Riode Base
 */
(function ($) {

  // Riode Properties
  Riode.$window = $( window );
  Riode.$body = $( document.body );
  Riode.status = '';                                           // Riode Status
  Riode.minDesktopWidth = 992;                                 // Detect desktop screen
  Riode.isIE = navigator.userAgent.indexOf( "Trident" ) >= 0;  // Detect Internet Explorer
  Riode.isEdge = navigator.userAgent.indexOf( "Edge" ) >= 0;   // Detect Edge
  Riode.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent );   // Detect Mobile
  Riode.defaults = {
    animation: {
      name: 'fadeIn',
      duration: '1.2s',
      delay: '.2s'
    },
    isotope: {
      itemsSelector: '.grid-item',
      layoutMode: 'masonry',
      percentPosition: true,
      masonry: {
        columnWidth: '.grid-space'
      }
    },
    minipopup: {
      // info
      message: '',
      productClass: '', // ' product-cart', ' product-list-sm'
      imageSrc: '',
      imageLink: '#',
      name: '',
      nameLink: '#', // 'product.html',
      price: '',
      count: null,
      rating: null,
      actionTemplate: '',
      isPurchased: false,

      // option
      delay: 4000, // milliseconds
      space: 20,

      // template
      priceTemplate: '<span class="product-price">{{price}}</span>',
      ratingTemplate: '<div class="ratings-container"><div class="ratings-full"><span class="ratings" style="width:{{rating}}"></span><span class="tooltiptext tooltip-top"></span></div></div>',
      priceQuantityTemplate: '<div class="price-box"><span class="product-quantity">{{count}}</span><span class="product-price">{{price}}</span></div>',
      purchasedTemplate: '<span class="purchased-time">12 MINUTES AGO</span>',

      template: '<div class="minipopup-box"><p class="minipopup-title">{{message}}</p>' +
        '<div class="product product-purchased {{productClass}} mb-0">' +
        '<figure class="product-media"><a href="{{imageLink}}"><img src="{{imageSrc}}" alt="product" width="90" height="90"></a></figure>' +
        '<div class="product-detail">' +
        '<a href="{{nameLink}}" class="product-name">{{name}}</a>' +
        '{{detailTemplate}}' +
        '</div>' +
        '</div>' +
        '{{actionTemplate}}' +
        '</div>',
    },
    popup: {
      removalDelay: 350,
      callbacks: {
        open: function () {
          $( 'html' ).css( 'overflow-y', 'hidden' );
          $( 'body' ).css( 'overflow-x', 'visible' );
          $( '.mfp-wrap' ).css( 'overflow', 'hidden auto' );
          $( '.sticky-header.fixed' ).css( 'padding-right', window.innerWidth - document.body.clientWidth );
        },
        close: function () {
          $( 'html' ).css( 'overflow-y', '' );
          $( 'body' ).css( 'overflow-x', 'hidden' );
          $( '.mfp-wrap' ).css( 'overflow', '' );
          $( '.sticky-header.fixed' ).css( 'padding-right', '' );
        }
      }
    },
    popupPresets: {
      login: {
        type: 'ajax',
        mainClass: "mfp-login mfp-fade",
        tLoading: '',
        preloader: false
      },
      video: {
        type: 'iframe',
        mainClass: "mfp-fade",
        preloader: false,
        closeBtnInside: false
      },
      quickview: {
        type: 'ajax',
        mainClass: "mfp-product mfp-fade",
        tLoading: '',
        preloader: false
      }
    },
    slider: {
      responsiveClass: true,
      navText: [ '<i class="d-icon-angle-left">', '<i class="d-icon-angle-right">' ],
      checkVisible: false,
      items: 1,
      smartSpeed: Riode.isEdge ? 200 : 500,
      autoplaySpeed: Riode.isEdge ? 200 : 1000,
      autoplayTimeout: 10000

    },
    sliderPresets: {
      'intro-slider': {
        animateIn: 'fadeIn',
        animateOut: 'fadeOut'
      },
      'product-single-carousel': {
        dots: false,
        nav: true,
      },
      'product-gallery-carousel': {
        dots: false,
        nav: true,
        margin: 20,
        items: 1,
        responsive: {
          576: {
            items: 2
          },
          768: {
            items: 3
          }
        },
      },
      'rotate-slider': {
        dots: false,
        nav: true,
        margin: 0,
        items: 1,
        animateIn: '',
        animateOut: ''
      }
    },
    sliderThumbs: {
      margin: 0,
      items: 4,
      dots: false,
      nav: true,
      navText: [ '<i class="fas fa-chevron-left">', '<i class="fas fa-chevron-right">' ]
    },
    stickyContent: {
      minWidth: Riode.minDesktopWidth,
      maxWidth: 20000,
      top: 300,
      hide: false, // hide when it is not sticky.
      max_index: 1060, // maximum z-index of sticky contents
      scrollMode: false
    },
    stickyHeader: {
      // activeScreenWidth: Riode.minDesktopWidth
      activeScreenWidth: 768
    },
    stickyFooter: {
      minWidth: 0,
      maxWidth: 767,
      top: 150,
      hide: true,
      scrollMode: true
    },
    stickyToolbox: {
      minWidth: 0,
      maxWidth: 767,
      top: false,
      scrollMode: true
    },
    stickySidebar: {
      autoInit: true,
      minWidth: 991,
      containerSelector: '.sticky-sidebar-wrapper',
      autoFit: true,
      activeClass: 'sticky-sidebar-fixed',
      paddingOffsetTop: 93,
      paddingOffsetBottom: 0,
    },
    templateCartAddedAlert: '<div class="alert alert-simple alert-btn cart-added-alert">' +
      '<a href="cart.html" class="btn btn-success btn-md">View Cart</a>' +
      '<span>"{{name}}" has been added to your cart.</span>' +
      '<button type="button" class="btn btn-link btn-close"><i class="d-icon-times"></i></button>' +
      '</div>'
  }

  /**
   * Get jQuery object
   * @param {string|jQuery} selector
   */
  Riode.$ = function ( selector ) {
    return selector instanceof jQuery ? selector : $( selector );
  }

  /**
   * Make a macro task
   * @param {function} fn
   * @param {number} delay
   */
  Riode.call = function ( fn, delay ) {
    setTimeout( fn, delay );
  }

  /**
   * Get dom element by id
   * @param {string} id
   */
  Riode.byId = function ( id ) {
    return document.getElementById( id );
  }

  /**
   * Get dom elements by tagName
   * @param {string} tagName
   * @param {HTMLElement} element this can be omitted.
   */
  Riode.byTag = function ( tagName, element ) {
    return element ?
      element.getElementsByTagName( tagName ) :
      document.getElementsByTagName( tagName );
  }

  /**
   * Get dom elements by className
   * @param {string} className
   * @param {HTMLElement} element this can be omitted.
   */
  Riode.byClass = function ( className, element ) {
    return element ?
      element.getElementsByClassName( className ) :
      document.getElementsByClassName( className );
  }

  /**
   * Set cookie
   * @param {string} name Cookie name
   * @param {string} value Cookie value
   * @param {number} exdays Expire period
   */
  Riode.setCookie = function ( name, value, exdays ) {
    var date = new Date();
    date.setTime( date.getTime() + ( exdays * 24 * 60 * 60 * 1000 ) );
    document.cookie = name + "=" + value + ";expires=" + date.toUTCString() + ";path=/";
  }

  /**
   * Get cookie
   * @param {string} name Cookie name
   */
  Riode.getCookie = function ( name ) {
    var n = name + "=";
    var ca = document.cookie.split( ';' );
    for ( var i = 0; i < ca.length; ++i ) {
      var c = ca[ i ];
      while ( c.charAt( 0 ) == ' ' ) {
        c = c.substring( 1 );
      }
      if ( c.indexOf( n ) == 0 ) {
        return c.substring( n.length, c.length );
      }
    }
    return "";
  }

  /**
   * Parse options string to object
   * @param {string} options
   */
  Riode.parseOptions = function ( options ) {
    return 'string' == typeof options ?

      JSON.parse( options.replace( /'/g, '"' ).replace( ';', '' ) ) :
      {};
  }

  /**
   * Parse html template with variables.
   * @param {string} template
   * @param {object} vars
   */
  Riode.parseTemplate = function ( template, vars ) {
    return template.replace( /\{\{(\w+)\}\}/g, function () {
      return vars[ arguments[ 1 ] ];
    } );
  }

  /**
   * @function isOnScreen
   * @param {HTMLElement} el
   * @param {number} dx
   * @param {number} dy
   */
  Riode.isOnScreen = function ( el, dx, dy ) {
    var a = window.pageXOffset,
      b = window.pageYOffset,
      o = el.getBoundingClientRect(),
      x = o.left + a,
      y = o.top + b,
      ax = typeof dx == 'undefined' ? 0 : dx,
      ay = typeof dy == 'undefined' ? 0 : dy;

    return y + o.height + ay >= b &&
      y <= b + window.innerHeight + ay &&
      x + o.width + ax >= a &&
      x <= a + window.innerWidth + ax;
  }

})(jQuery);
