function onePageCheckoutForm() {
  var form = $("#sylius-one-page-checkout");
  var _this = this;

  this.loadShippingMethods = function(countryCode, submit) {
    $.ajax({
      url: '/cs_CZ/checkout/load-shipping-methods',
      type: 'PUT',
      data: {
        countryCode: countryCode
      },
      success: function(data) {
        $("#one-page-select-shipping-area").html(data);
        _this.loadSummaryData();

        $("#next-step").click();
      }
    });
  }

  this.setVatPayer = function(isVatPayer, submit) {
    $.ajax({
      url: '/cs_CZ/checkout/set-vat-payer',
      type: 'PUT',
      data: {
        isVatPayer: isVatPayer
      },
      success: function(data) {
        _this.loadSummaryData();
        $("#next-step").click();
      }
    });
  }

  this.loadPaymentMethods = function(shippingMethodCode) {
    $.ajax({
      url: '/cs_CZ/checkout/load-payment-methods',
      type: 'PUT',
      data: {
        shippingMethodCode: shippingMethodCode
      },
      success: function(data) {
        $("#one-page-select-payment-area").html(data);
        $('input[name^="checkout_onepage[payments]"]').each(function () {
          var $this = $(this);
          $this.prop('checked', false);
        });
        _this.loadSummaryData();
      }
    });
  }

  this.loadSummaryData = function(paymentMethodCode) {
    if(paymentMethodCode === undefined) {
      paymentMethodCode = null;
    }

    $.ajax({
      url: '/cs_CZ/checkout/load-summary',
      type: 'GET',
      data: {
        paymentMethodCode: paymentMethodCode
      },
      success: function(data) {
        $("#summary-container").html(data);
      }
    });
  }
}

module.exports = onePageCheckoutForm;
