/**
 * Riode Dependent Plugin - Slider
 *
 * @requires OwlCarousel
 * @instance multiple
 */


(function ($) {

  /**
   * @function slider
   *
   * @requires OwlCarousel
   */
  Riode.slider = ( function () {
    /**
     * @class Slider
     */
    function Slider ( $el, options ) {
      return this.init( $el, options );
    }

    var onInitialize = function ( e ) {
      var i, j, breaks = [ '', '-xs', '-sm', '-md', '-lg', '-xl' ];
      this.classList.remove( 'row' );
      for ( i = 0; i < 6; ++i ) {
        for ( j = 1; j <= 12; ++j ) {
          this.classList.remove( 'cols' + breaks[ i ] + '-' + j );
        }
      }
      this.classList.remove( 'gutter-no' );
      this.classList.remove( 'gutter-sm' );
      this.classList.remove( 'gutter-lg' );
      if ( this.classList.contains( "animation-slider" ) ) {
        var els = this.children,
          len = els.length;
        for ( var i = 0; i < len; ++i ) {
          els[ i ].setAttribute( 'data-index', i + 1 );
        }
      }
    }
    var onInitialized = function ( e ) {
      var els = this.firstElementChild.firstElementChild.children,
        i,
        len = els.length;
      for ( i = 0; i < len; ++i ) {
        if ( !els[ i ].classList.contains( 'active' ) ) {
          var animates = Riode.byClass( 'appear-animate', els[ i ] ),
            j;
          for ( j = animates.length - 1; j >= 0; --j ) {
            animates[ j ].classList.remove( 'appear-animate' );
          }
        }
      }

      // Video
      var $el = $( e.currentTarget );
      $el.find( 'video' ).on( 'ended', function () {
        var $this = $( this );
        if ( $this.closest( '.owl-item' ).hasClass( 'active' ) ) {
          if ( true === $el.data( 'owl.carousel' ).options.autoplay ) {
            if ( false === $el.data( 'owl.carousel' ).options.loop && ( $el.data().children - 1 ) === $el.find( '.owl-item.active' ).index() ) {
              this.loop = true;
              this.play();
            }
            $el.trigger( 'next.owl.carousel' );
            $el.trigger( 'play.owl.autoplay' );
          } else {
            this.loop = true;
            this.play();
          }
        }
      } );
    }
    var onTranslated = function ( e ) {
      $( window ).trigger( 'appear.check' );

      // Video Play
      var $el = $( e.currentTarget ),
        $activeVideos = $el.find( '.owl-item.active video' );

      $el.find( '.owl-item:not(.active) video' ).each( function () {
        if ( !this.paused ) {
          $el.trigger( 'play.owl.autoplay' );
        }
        this.pause();
        this.currentTime = 0;
      } );

      if ( $activeVideos.length ) {
        if ( true === $el.data( 'owl.carousel' ).options.autoplay ) {
          $el.trigger( 'stop.owl.autoplay' );
        }
        $activeVideos.each( function () {
          this.paused && this.play();
        } );
      }
    }
    var onSliderInitialized = function ( e ) {
      var self = this,
        $el = $( e.currentTarget );

      // carousel content animation

      $el.find( '.owl-item.active .slide-animate' ).each( function () {
        var $animation_item = $( this ),
          settings = $.extend( true, {},
            Riode.defaults.animation,
            Riode.parseOptions( $animation_item.data( 'animation-options' ) )
          ),
          duration = settings.duration,
          delay = settings.delay,
          aniName = settings.name;

        $animation_item.css( 'animation-duration', duration );

        var temp = Riode.requestTimeout( function () {
          $animation_item.addClass( aniName );
          $animation_item.addClass( 'show-content' );
        }, ( delay ? Number( ( delay ).slice( 0, -1 ) ) * 1000 : 0 ) );

        self.timers.push( temp );
      } );
    }

    var onSliderResized = function ( e ) {
      $( e.currentTarget ).find( '.owl-item.active .slide-animate' ).each( function () {
        var $animation_item = $( this );
        $animation_item.addClass( 'show-content' );
        $animation_item.attr( 'style', '' );
      } );
    }

    var onSliderTranslate = function ( e ) {
      var self = this,
        $el = $( e.currentTarget );
      self.translateFlag = 1;
      self.prev = self.next;
      $el.find( '.owl-item .slide-animate' ).each( function () {
        var $animation_item = $( this ),
          settings = $.extend( true, {}, Riode.defaults.animation, Riode.parseOptions( $animation_item.data( 'animation-options' ) ) );
        $animation_item.removeClass( settings.name );
      } );
    }

    var onSliderTranslated = function ( e ) {
      var self = this,
        $el = $( e.currentTarget );
      if ( 1 == self.translateFlag ) {
        self.next = $el.find( '.owl-item' ).eq( e.item.index ).children().attr( 'data-index' );
        $el.find( '.show-content' ).removeClass( 'show-content' );
        if ( self.prev != self.next ) {
          $el.find( '.show-content' ).removeClass( 'show-content' );
          /* clear all animations that are running. */
          if ( $el.hasClass( "animation-slider" ) ) {
            for ( var i = 0; i < self.timers.length; i++ ) {
              Riode.deleteTimeout( self.timers[ i ] );
            }
            self.timers = [];
          }
          $el.find( '.owl-item.active .slide-animate' ).each( function () {
            var $animation_item = $( this ),
              settings = $.extend( true, {}, Riode.defaults.animation, Riode.parseOptions( $animation_item.data( 'animation-options' ) ) ),
              duration = settings.duration,
              delay = settings.delay,
              aniName = settings.name;

            $animation_item.css( 'animation-duration', duration );
            $animation_item.css( 'animation-delay', delay );
            $animation_item.css( 'transition-property', 'visibility, opacity' );
            $animation_item.css( 'transition-delay', delay );
            $animation_item.css( 'transition-duration', duration );
            $animation_item.addClass( aniName );

            duration = duration ? duration : '0.75s';
            $animation_item.addClass( 'show-content' );
            var temp = Riode.requestTimeout( function () {
              $animation_item.css( 'transition-property', '' );
              $animation_item.css( 'transition-delay', '' );
              $animation_item.css( 'transition-duration', '' );
              self.timers.splice( self.timers.indexOf( temp ), 1 )
            }, ( delay ? Number( ( delay ).slice( 0, -1 ) ) * 1000 + Number( ( duration ).slice( 0, -1 ) ) * 500 : Number( ( duration ).slice( 0, -1 ) ) * 500 ) );
            self.timers.push( temp );
          } );
        } else {
          $el.find( '.owl-item' ).eq( e.item.index ).find( '.slide-animate' ).addClass( 'show-content' );
        }
        self.translateFlag = 0;
      }
    }

    // Public Properties

    Slider.zoomImage = function () {

    }

    Slider.zoomImageRefresh = function () {
      this.$element.find( 'img' ).each( function () {
        var $this = $( this );

        if ( $.fn.elevateZoom ) {
          var elevateZoom = $this.data( 'elevateZoom' );
          if ( typeof elevateZoom !== 'undefined' ) {
            elevateZoom.refresh();
          } else {
            Riode.defaults.zoomImage.zoomContainer = $this.parent();
            $this.elevateZoom( Riode.defaults.zoomImage );
          }
        }
      } );
    }

    Riode.defaults.sliderPresets[ 'product-single-carousel' ].onInitialized = Riode.defaults.sliderPresets[ 'product-gallery-carousel' ].onInitialized = Slider.zoomImage;
    Riode.defaults.sliderPresets[ 'product-single-carousel' ].onRefreshed = Riode.defaults.sliderPresets[ 'product-gallery-carousel' ].onRefreshed = Slider.zoomImageRefresh;

    Slider.prototype.init = function ( $el, options ) {
      this.timers = [];
      this.translateFlag = 0;
      this.prev = 1;
      this.next = 1;

      Riode.lazyload( $el, true );

      var classes = $el.attr( 'class' ).split( ' ' ),
        settings = $.extend( true, {}, Riode.defaults.slider );

      // extend preset options
      classes.forEach( function ( className ) {
        var preset = Riode.defaults.sliderPresets[ className ];
        preset && $.extend( true, settings, preset );
      } );

      var $videos = $el.find( 'video' );
      $videos.each( function () {
        this.loop = false;
      } );

      // extend user options
      $.extend( true, settings, Riode.parseOptions( $el.attr( 'data-owl-options' ) ), options );

      onSliderInitialized = onSliderInitialized.bind( this );
      onSliderTranslate = onSliderTranslate.bind( this );
      onSliderTranslated = onSliderTranslated.bind( this );

      // init
      $el.on( 'initialize.owl.carousel', onInitialize )
        .on( 'initialized.owl.carousel', onInitialized )
        .on( 'translated.owl.carousel', onTranslated );

      // if animation slider
      $el.hasClass( 'animation-slider' ) &&
      $el.on( 'initialized.owl.carousel', onSliderInitialized )
        .on( 'resized.owl.carousel', onSliderResized )
        .on( 'translate.owl.carousel', onSliderTranslate )
        .on( 'translated.owl.carousel', onSliderTranslated );

      $el.owlCarousel( settings );
    }

    return function ( selector, options ) {
      Riode.$( selector ).each( function () {
        var $this = $( this );

        Riode.call( function () {
          new Slider( $this, options );
        } );
      } );
    }
  } )();

})(jQuery);

