/**
 * Riode Plugin - Product Single Page
 *
 * @requires Slider
 * @requires ProductSingle
 * @requires PhotoSwipe
 * @instance single
 */

(function ($) {

  /**
   * @function initProductSinglePage
   *
   * @requires Slider
   * @requires ProductSingle
   * @requires PhotoSwipe
   * @instance single
   */
  Riode.initProductSinglePage = ( function () {
    function alertCartAdded ( e ) {
      var $product = $( e.currentTarget ).closest( '.product-single' );
      $( '.cart-added-alert' ).remove();
      $( Riode.parseTemplate( Riode.defaults.templateCartAddedAlert, {
        name: $product.find( 'h1.product-name' ).text()
      } ) )
        .insertBefore( $product ).fadeIn();
      $( '.sticky-sidebar' ).trigger( 'recalc.pin' );
    }
    function openFullImage ( e ) {
      e.preventDefault();

      var $this = $( e.currentTarget ),
        $product = $this.closest( '.product-single' ),
        $images,
        images;

      if ( $product.find( '.product-single-carousel' ).length ) {
        // single carousel
        $images = $product.find( '.product-single-carousel .owl-item:not(.cloned) img' );

      } else if ( $product.find( '.product-gallery-carousel' ).length ) {
        // gallery carousel
        $images = $product.find( '.product-gallery-carousel .owl-item:not(.cloned) img' );

      } else {
        // simple gallery
        $images = $product.find( '.product-gallery img' );
      }

      // if images exist

    }
    function ratingForm () {
      Riode.$body.on( 'click', '.rating-form .rating-stars > a', function ( e ) {
        var $star = $( this );
        $star.addClass( 'active' ).siblings().removeClass( 'active' );
        $star.parent().addClass( 'selected' );
        $star.closest( '.rating-form' ).find( 'select' ).val( $star.text() );
        e.preventDefault();
      } );
    }

    function initWishlistAction ( e ) {
      var $this = $( e.currentTarget );
      if ( $this.hasClass( 'added' ) ) {
        return;
      }
      e.preventDefault();
      $this.addClass( 'load-more-overlay loading' );

      setTimeout( function () {
        $this
          .removeClass( 'load-more-overlay loading' )
          .html( '<i class="d-icon-heart-full"></i> Browse wishlist' )
          .addClass( 'added' )
          .attr( 'title', 'Browse wishlist' )
          .attr( 'href', 'wishlist.html' );
      }, 500 );
    }

    // Public Properties
    return function () {
      var $product = $( '.product-single' );
      // Wishlist button
      Riode.$body.on( 'click', '.product-single .btn-wishlist', initWishlistAction );

      if ( $product.length ) {
        // if home page, init single products
        if ( document.body.classList.contains( 'home' ) ) {
          $product.each( function () {
            Riode.initProductSingle( $( this ) );
          } );

          return null;

          // else, init single product page
        } else {
          if ( Riode.initProductSingle( $product ) === null ) {
            return null;
          }
        }
      } else {
        // if no single product exists, return
        return null;
      }

      // image full
      Riode.$body.on( 'click', '.product-single .product-image-full', openFullImage );

      // cart added alert
      // Riode.$body.on( 'click', '.single-product .btn-cart:not(.disabled)', alertCartAdded );


      // init rating from.(new)
      ratingForm();
    }
  } )();

})(jQuery);
