global.plxPos = 0;

import './scss/vendor.scss';

import './packages/animate/animate.min.css';
import './packages/nouislider/nouislider.min.css';
import './images/logo.png';
import './images/logo_katalog.png';
import './images/logo_default.png';
import './images/logo.jpg';
import './images/payment.png';
import './images/lazy.png';
import './images/instagram/01.jpg';
import './images/favicon.ico';
import './scss/index.scss';
import './js/index';
