/* jQuery easing */
jQuery.extend( $.easing, {
  def: 'easeOutQuad',
  swing: function ( x, t, b, c, d ) {
    return $.easing[ $.easing.def ]( x, t, b, c, d );
  },
  easeOutQuad: function ( x, t, b, c, d ) {
    return -c * ( t /= d ) * ( t - 2 ) + b;
  },
  easeOutQuint: function ( x, t, b, c, d ) {
    return c * ( ( t = t / d - 1 ) * t * t * t * t + 1 ) + b;
  }
} );
