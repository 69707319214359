import $ from "jquery";
import 'jquery-ui/ui/widgets/autocomplete';
import './libs/sylius-api-login';
import './libs/sylius-api-register';
import './libs/sylius-add-to-cart';

var onePageCheckoutForm = require('./libs/sylius-checkout-one-page');

function GetURLParameter(sParam) {
  var sPageURL = window.location.search.substring(1);
  var sURLVariables = sPageURL.split('&');
  for (var i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] == sParam) {
      return sParameterName[1];
    }
  }
}

$(document).ready(() => {

  $("#listing-orderby").on('change', function (el) {

    const element = $(this);
    window.location.href = element.val();
  });

  $('.lazy').Lazy();
    $( '.header-search input[type="search"]' ).autocomplete({
      source: '/cs_CZ/auto-complete/product-all',
      minLength: 2,
      select: function( event, ui ) {
        window.location.href = ui.item.slug;
      }
    }).autocomplete( "instance" )._renderItem = function( ul, item ) {
      return $( "<li>" )
        .append( "<div class='row'><div class='col-3'><img src='" + item.image + "' /></div><div class='col-9'> " + item.name + "</div></div>" )
        .appendTo( ul );
    };

  $( '.mobile-menu-container input[type="search"]' ).autocomplete({
    source: '/cs_CZ/auto-complete/product-all',
    minLength: 2,
    select: function( event, ui ) {
      window.location.href = ui.item.slug;
    }
  }).autocomplete( "instance" )._renderItem = function( ul, item ) {
    return $( "<li>" )
      .append( "<div class='row'><div class='col-3'><img src='" + item.image + "' /></div><div class='col-9'> " + item.name + "</div></div>" )
      .appendTo( ul );
  };

    $( '.header-search-wrapper input[type="search"]' ).autocomplete({
      source: '/cs_CZ/auto-complete/product-all',
      minLength: 2,
      select: function( event, ui ) {
        window.location.href = ui.item.slug;
        return;
      }
    }).autocomplete( "instance" )._renderItem = function( ul, item ) {
      return $( "<li>" )
        .append( "<div class='row'><div class='col-3'><img src='" + item.image + "' /></div><div class='col-9'> " + item.name + "</div></div>" )
        .appendTo( ul );
    };

  $('#signin').apiLogin({
    method: 'POST',
    throttle: 500,
  });

  var checkoutForm = new onePageCheckoutForm();

  var shippingMethodsContainer = $("#one-page-select-shipping-area");

  $(document).on('change', "#checkout_onepage_billingAddress_countryCode", function (element) {
    var countryCode = $(this).val();

    checkoutForm.loadShippingMethods(countryCode, true);

    $("#checkout_onepage_shippingAddress_countryCode").val(countryCode);
  });

  $('.quantity-change-cart').on('change', function() {
    // $("#sylius-cart-update").click();
  });

  $('#checkout_onepage_isVatPayer').on('change', function () {
    if ($(this).is(':checked')) {
      checkoutForm.setVatPayer(1);
    } else {
      checkoutForm.setVatPayer(0);
    }
    // alert($(this).val());

    // $("#next-step").click();
  });

  // $('#sylius-product-adding-to-cart').addToCart();

  $(document).on('click', '.filterAttributeItem', function () {
    var $item = $(this);
    var $filterItemId = $item.data('filterId');
    var checkBoxes = $("#" + $filterItemId);

    checkBoxes.prop("checked", !checkBoxes.prop("checked"));

    $("#search-filter-form").submit();
  });

  $('.img-menu-lazy').lazy({
    bind: "event",
    delay: 0
  });

  $('#register').apiRegistration({
    method: 'POST',
    throttle: 500,
  });


  $(document).on('change', ".country-vat-secondary-change", function (element) {
    var countryId = $(this).val();

    if (countryId == 2) {
      $("#vat-number-secondary").show();
    } else {
      $("#vat-number-secondary").hide();
    }

  });

  $(document).on('change', "#checkout_onepage_billingAddress_countryCode", function (element) {
    var countryCode = $(this).val();

    if ($("#checkout_onepage_differentShippingAddress").is(':checked')) {
      countryCode = $("#checkout_onepage_shippingAddress_countryCode").val();
    }

    checkoutForm.loadShippingMethods(countryCode);
  });

  $(document).on('change', "#one-page-select-shipping-area input[type='radio']", function (element) {
    checkoutForm.loadPaymentMethods($(this).val());
  });

  $(document).on('change', "#one-page-select-payment-area input[type='radio']", function (element) {
    checkoutForm.loadSummaryData($(this).val());
  });

  $(document).on('click', '#sylius_checkout_address_differentShippingAddress', function () {
    $("#sylius-shipping-address").toggle();
  });

  $(document).on('click', '#checkout_onepage_isCompanyOrder', function () {
    console.log($("#checkout_onepage_isCompanyOrder").is(':checked'));
    if (!$("#checkout_onepage_isCompanyOrder").is(':checked')) {
      $("#checkout_onepage_billingAddress_company").val('');
      $("#checkout_onepage_billingAddress_vatNumber").val('');
      $("#checkout_onepage_billingAddress_identificationNumber").val('');
    }
    $("#billingAddress-company-data").toggle();
  });

  if ($("#checkout_onepage_isCompanyOrder").length > 0 && $("#checkout_onepage_isCompanyOrder").is(':checked')) {
    $("#billingAddress-company-data").toggle();
  }

  if ($("#checkout_onepage_differentShippingAddress").length > 0 && $("#checkout_onepage_differentShippingAddress").is(':checked')) {
    $("#sylius-shipping-address").toggle();
  }

  $('.selectOnChangeRedirect li').each(function () {
    var $this = $(this);
    $this.on("click", function () {
      window.location = $(this).data('value');
    });
  });

  $('.gls-parcel-shop-open').magnificPopup({
    type: 'iframe'
  });

  $(document).on('click', '#pickup-point-provider-select', function (el) {
    el.preventDefault();
  });

});
